//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'

const useCaseData = [
  {
    title: 'Restaurant safety: the #1 imperative',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      "Your restaurant's number one imperative while adapting to the new normal is to keep your customers and employees safe - so they'll keep coming back",
    descriptionColor: 'white-1',
    image: 'usecaseRestaurant',
    iconShow: false,
    // iconColor: "purple",
    lineClass: 'green-1',
    bgcolor: 'blue-1',
    bggradient: 'cool',
  },
  {
    title: 'Automated mobile health self-assessments',
    titleWeight: 'medium',
    description:
      'Keep a pulse on the health and safety of your restaurant environment by asking your employees to complete a health self-certification before their shift, and log that data as proof of your commitment to safety',
    image: 'usecaseRestaurantSolution',

    iconShow: false,
    iconColor: 'pink',
    lineClass: 'blue-5',
    // videosource: 'brandengagement',
  },
]
const featureData = {
  title: 'Create a safe restaurant environment',
  titlecolor: 'black-4',
  mode: 'trio',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Reduce health uncertainty',
          colbgcolor: 'green-1',
          iconname: 'health',
          description:
            'Automated health self-certifications to mitigate liability from unwell employees or invividuals in your restaurant',
        },
        {
          title: 'Use risk-based exception alerts',
          colbgcolor: 'dkgreen-1',
          iconname: 'megaphone',
          description:
            'Alert managers of exceptions to your risk-based protocols via text or email',
        },
        {
          title: 'Communicate confidence to customers',
          colbgcolor: 'yellow-1',
          iconname: 'users',
          description:
            'Improve customer confidence in your business by communicating your commitment to a safe environment',
        },
      ],
    },
  ],
}
const About = props => (
  <Layout>
    <Helmet
      title="Picohealth - Restaurants - Automated Mobile Health Assessments"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how the Picohealth automated mobile health assessment platform helps reduce risk and drive customer confidence for your restaurant',
        },
        {
          name: 'keywords',
          content:
            'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium bluegreen-1">
            Help assure your restaurant is safe
          </h1>
          <p class="display-4">
            Use automated employee health self-certifications to help make sure
            your restaurant environment is healthy and safe
          </p>
        </div>
      </div>
    </div>

    {/* <DividerMid
      class="green-1"
      bottomclass="white-4"
      flip={false}
      lineclass="blue-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="purple-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    <AlternatingCallout featureData={useCaseData} />
    {/* 
    <BenefitsBoomerang
      color="white-1"
      bgcolor="blue-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Title 1",
          body:
            "Multiply the reach of social campaigns through compelling, mass-personalized videos.",
        },
        {
          title: "Title 2",
          body:
            "Measure the impact of personalized video with social landing pages and analytics",
        },
        {
          title: "Title 3",
          body:
            "Use a variety of creative treatments to engage users, and ensure brand-safe content with moderation",
        },
      ]}
    /> */}

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
